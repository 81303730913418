import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireMessaging } from 'angularfire2/messaging';
import { BehaviorSubject } from 'rxjs';
import { ApisService } from './services/apis.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  currentMessage = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private translate: TranslateService,
    private api: ApisService,
    private angularFireMessaging: AngularFireMessaging) {
    const lng = localStorage.getItem('lng');
    if (!lng || lng === null) {
      localStorage.setItem('lng', 'spanish');
    }
    this.translate.use(localStorage.getItem('lng'));
    this.angularFireMessaging.messages.subscribe(
      (message) => {
        console.log('Mensaje recibido:', message);
        this.currentMessage.next(message);
      }
    );

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // this.requestPermission();
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log('Token de dispositivo:', token);
        this.api.getProfile('2eUklws9FNPk0nFDwEBZQ62p0es2').then((admin => {
          console.log("EL PERFIL DEL ADMIN",admin);
          if (!admin.notificationTokens.includes(token)) {
            console.log("No incluye el param");
            
            let param = {
              notificationTokens: admin.notificationTokens.concat(token)
            }
            console.log("ESTE ES EL TIPO DE DATO DE LOS TOKENS",typeof(admin.notificationTokens),admin.notificationTokens);
            
            // if (typeof(admin.notificationTokens)) {
              
            // }
            this.api.updateProfile('2eUklws9FNPk0nFDwEBZQ62p0es2', param)
          } else {
            console.log("El usuario admin ya tenia el token de este dispositivo");
            
          }
        }))
        // Aquí puedes guardar el token en tu base de datos o enviarlo a tu backend para futuras notificaciones
      },
      (error) => {
        console.error('Error al solicitar el permiso de notificación:', error);
      }
    );
  }



}
